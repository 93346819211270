import * as React from "react";

const NotFoundPage = () => {
  return (
    <div>
      <h1>Page you are looking for doesn't exist</h1>
    </div>
  );
};

export default NotFoundPage;
