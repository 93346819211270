import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./App.css";

import Layout from "./containers/Layout";
import Router from "./Router";

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Layout>
        <Router />
      </Layout>
    </>
  );
};

export default App;
