// tslint:disable: no-any
import * as React from "react";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, withStyles } from "@material-ui/core";

import { RouteComponentProps } from "react-router";
import queryString from "query-string";
import ReactSVG from "react-svg";
import { format as formatDate } from "date-fns";
import NoSleep from "../../utils/nosleep";
import { restoreApiKey } from "../../utils/apikey";
import { getInstanceId } from "../../utils";

// logos
import LoaderIcon from "../../assets/loading-progress.svg";
import DefaultToolbarIcon from "../../assets/logos/default.svg";
import FreshworksToolbarIcon from "../../assets/logos/freshworks.svg";

import { getConfig } from "../../sdk/config";
import {
  doProcessForGenesys,
  initialTestRunState,
  runPeriodicTest,
  TestRunState,
} from "../../test-run/test-run-logic";
import LogView from "./LogView";
import isString from "lodash/isString";

import { ILayoutContext, LayoutContext } from "../Layout";

export const frequencyVariants: any = {
  "5min": 5,
  "15min": 15,
  "1hour": 60,
  "4hour": 60 * 4,
  "6hour": 60 * 6,
  "8hour": 60 * 8,
  "12hour": 60 * 12,
  daily: 60 * 24,
  never: "never",
};

type Props = {
  classes: any;
};

type State = {
  testRun: TestRunState;
  loading: boolean;
  showLog: boolean;
  logs: any[];
  start: boolean;
  error: string;
};

class Probe extends React.Component<Props & RouteComponentProps<{}>, State> {
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);
    this.state = {
      testRun: initialTestRunState,

      loading: false,
      showLog: false,
      logs: [],
      start: false,
      error: "",
    };
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.handleOnBeforeUnload);
    const { autoStart } = queryString.parse(this.props.location.search);
    if (autoStart === "true" || localStorage.getItem("page-restart") === "true") {
      // need timeout because of CallQuality test warning:
      // The AudioContext was not allowed to start. It must be resumed (or created) after a user gesture on the page.
      setTimeout(() => {
        this.startApp();
      }, 2000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleOnBeforeUnload);
  }

  handleOnBeforeUnload = (e: any) => {
    e.preventDefault();
    const message = `If you close the page the probeRTC will stop to work and will not send any more results,
    are you sure you want to close?`;
    e.returnValue = message;
    return message;
  };

  startApp = async () => {
    await this.setState({
      ...this.state,
      start: true,
    });
    const nosleep = new NoSleep();
    nosleep.enable();
    this.runApp();
  };
  startTests = () => {
    localStorage.setItem("page-restart", "true");
    this.startApp();
  };
  restartTests = () => {
    localStorage.setItem("restartButton", "true");
    this.startApp();
  };

  runApp = async () => {
    let nextTimeEvent = 60000; // 1 minute
    this.readConfig()
      .then(async (config: any) => {
        this.addLogLine("Config read");

        await doProcessForGenesys(config);

        await this.setState({
          ...this.state,
          error: "",
        });
        runPeriodicTest(this.state.testRun, config, this.addLogLine).then(
          (newTestRun: TestRunState) => {
            if (newTestRun.reload) {
              window.removeEventListener("beforeunload", this.handleOnBeforeUnload);
              window.location.reload();
              nextTimeEvent = -1;
            } else {
              this.setState({
                ...this.state,
                testRun: newTestRun,
              });
              nextTimeEvent = newTestRun.nextEventTime - Date.now();
            }
          }
        );
      })
      .catch(async (err: any) => {
        await this.setState({
          ...this.state,
          error: err.message,
        });
      })
      .finally(() => {
        console.log(`index:runPeriodicTest:finally ${nextTimeEvent}`, {});
        if (nextTimeEvent !== -1) {
          window.setTimeout(this.runApp, nextTimeEvent);
        }
      });
  };

  readConfig = async () => {
    const instanceId = getInstanceId();
    const queryParams = queryString.parse(this.props.location.search);
    const apiKey = restoreApiKey(queryParams.apiKey as string);
    const envParam = queryParams.env as string || "";
    try {
      this.setState({
        ...this.state,
        loading: true,
      });
      return await getConfig({
        apiKey: apiKey?.toString() || "",
        instanceId,
        env: envParam
      });
    } catch (err) {
      console.error({ getConfigError: err });
      if (!apiKey) {
        throw Error(
          "Missing apiKey in url. URL should look like https://probe.testrtc.com/?apiKey=<id>"
        );
      } else if (err.response && err.response.data?.message === "probeRTC already running") {
        throw Error(
          "This probe is already running on a different machine. To run it here you will need to generate a new API key for it"
        );
      } else if (err.response && err.response.data?.message === "Invalid authentication credentials") {
        throw Error(
          "API key used to run this probe is wrong"
        );
      } else if (err?.message === "Probe date have been Expired") {
        throw Error(
          "This Probe date have been Expired. To run it here you will need to update the probe date"
        );
      } else {
        throw Error("Internal error. Failed to start, please try again later. If issue persist, please contact support.");
      }
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  addLogLine = async (line: string | any) => {
    let logs = this.state.logs;
    if (isString(line)) {
      logs.push({ message: line });
    } else {
      logs.push(line);
    }

    if (logs.length > 2500) {
      logs = logs.slice(logs.length - 2000);
    }

    await this.setState({
      ...this.state,
      logs,
    });
  };

  showNextDate = (ms: number | null, isThorough: boolean = false) => {
    if (isThorough && this.state.testRun.config.thorough_run_frequency === "never") {
      return "never";
    }
    if (!ms) {
      return "N/A";
    }
    const date = new Date(ms);
    return formatDate(date, "MMM d HH:mm");
  };

  toggleLog = async () => {
    await this.setState({
      ...this.state,
      showLog: !this.state.showLog,
    });
  };

  getLogo = (themeName: string) => {
    switch (themeName) {
      case "default":
        return DefaultToolbarIcon;
      case "freshworks":
        return FreshworksToolbarIcon;
      default:
        return DefaultToolbarIcon;
    }
  };

  render() {
    const { classes } = this.props;
    const { start, showLog, logs, loading, error, testRun } = this.state;
    const { autoStart } = queryString.parse(this.props.location.search);
    return (
      <>
        <LayoutContext.Consumer>
          {(props: ILayoutContext) => {
            const header = props.textDictionary?.appHeader || "probeRTC";
            return (
              <Paper className={classes.paper}>
                <Toolbar className={classes.toolbar}>
                  <ReactSVG src={this.getLogo(props.themeName)} className={classes.toolbarIcon}/>
                  <Typography variant="h4" className={classes.toolbarText}>
                    {header}
                  </Typography>
                </Toolbar>
                <Divider className={classes.divider}/>
                {!start && autoStart !== "true" && (
                  <div style={{ display: "flex", justifyContent: "center", marginTop: 70 }}>
                    <Button variant="contained" color={"primary"} onClick={this.startTests}>
                      {"Start"}
                    </Button>
                  </div>
                )}
                <>
                  {!loading && error && (
                    <div className={classes.errorContainer}>
                      <Typography>{error}</Typography>
                    </div>
                  )}
                  {loading && !testRun.config && (
                    <ReactSVG src={LoaderIcon} className={classes.progress}/>
                  )}
                  {!error && testRun.config && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography className={classes.configLabel}>Name:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            className={`${classes.configValue} ${
                              classes[`configValue_${props.themeName}`]
                            }`}
                          >
                            {testRun.config.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography className={classes.configLabel}>Description:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            className={`${classes.configValue} ${
                              classes[`configValue_${props.themeName}`]
                            }`}
                          >
                            {testRun.config.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography className={classes.configLabel}>Status:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            className={`${classes.configValue} ${
                              classes[`configValue_${props.themeName}`]
                            }`}
                          >
                            {testRun.config.status ? "Active" : "Inactive"}
                          </Typography>
                        </Grid>
                        {testRun.config.status && (
                          <>
                            <Grid item xs={4}>
                              <Typography className={classes.configLabel}>
                                Next test run:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                className={`${classes.configValue} ${
                                  classes[`configValue_${props.themeName}`]
                                }`}
                              >
                                {this.showNextDate(testRun.nextEvents[0])}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography className={classes.configLabel}>
                                Next Thorough run:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                className={`${classes.configValue} ${
                                  classes[`configValue_${props.themeName}`]
                                }`}
                              >
                                {this.showNextDate(testRun.nextEvents[1], true)}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} className={classes.buttonContainer}>
                          <Button
                            className={classes.buttonStyle}
                            variant="contained"
                            color={"primary"}
                            onClick={this.toggleLog}
                          >
                            {`${showLog ? "hide" : "show"} log`}
                          </Button>
                          <Button
                            className={classes.buttonStyle}
                            variant="contained"
                            color={"primary"}
                            onClick={this.restartTests}
                          >
                            Restart
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              </Paper>
            );
          }}
        </LayoutContext.Consumer>

        <Paper
          className={classes.logPaper}
          style={{
            visibility: showLog ? "visible" : "hidden",
          }}
        >
          <LogView logs={logs}/>
        </Paper>
      </>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(3),
      },
    },
    toolbar: {
      minHeight: 24,
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "baseline",
    },
    toolbarIcon: {
      display: "flex",
      justifyContent: "center",
      "& svg": {
        height: 50,
        width: 50,
        marginBottom: -16,
        fill: theme.palette.primary.dark,
        "& g": {
          "& *": {
            stroke: theme.palette.primary.dark,
            strokeWidth: 1.7,
          },
        },
      },
    },
    toolbarText: {
      marginBottom: -18,
      marginLeft: 20,
      fontSize: 27,
    },
    divider: {
      margin: "16px -16px",
    },
    configLabel: {
      fontSize: 13,
      marginTop: 3,
    },
    configValue: {},
    configValue_freshworks: {
      fontWeight: 600,
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    logPaper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(3),
      },
      maxHeight: 345,
      overflow: "auto",
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      "& svg": {
        height: 50,
        width: 50,
      },
    },
    errorContainer: {
      textAlign: "center",
      marginTop: 10,
      color: "#D8000C",
      backgroundColor: "#FFBABA",
      padding: 5,
      borderRadius: 5,
    },
    buttonStyle: {
      marginLeft: 15,
    },
  });

const decorate = withStyles(styles);

export default decorate(Probe);
