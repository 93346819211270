import { v4 as uuidv4 } from "uuid";
import { InstanceId } from "../constants/LSKeys";

export const getInstanceId = () => {
  let instanceId = localStorage.getItem(InstanceId);
  if (!instanceId) {
    instanceId = uuidv4();
    localStorage.setItem(InstanceId, instanceId);
  }
  return instanceId;
};
