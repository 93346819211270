type Environment = "production" | "staging" | "local";
interface IDecomposedApiKey {
  apiKey: string;
  url?: string;
  environment: Environment;
}
interface IEnvironmentMap {
  local: string;
  staging: string;
  production: string;
}

// for local setup we are using PR:{probeId} key
// because of kong authentication api key schema
// so for local use need to pass local:{probeId} and not generated apiKey
export const decomposeApiKey = (
  apiKey: string,
  environmentMap?: IEnvironmentMap
): IDecomposedApiKey => {
  const envs = ["local", "staging", "production"];
  const splitted = apiKey.split(":");

  if (envs.some((e) => apiKey.indexOf(e) >= 0)) {
    const env = splitted[0] as Environment;
    return {
      apiKey: splitted[1],
      environment: env,
      url: environmentMap ? environmentMap[env] : undefined,
    };
  } else {
    return {
      apiKey: splitted[0],
      environment: "production",
      url: environmentMap ? environmentMap["production"] : undefined,
    };
  }
};

export const simplifyApiKey = (apiKey: string) => {
  return apiKey.replace(/-/g, "");
};

export const restoreApiKey = (apiKey: string) => {
  const restore = (apiKey: string) => {
    return apiKey
      .replace(/^(.{8})/, "$1-")
      .replace(/^(.{13})/, "$1-")
      .replace(/^(.{18})/, "$1-")
      .replace(/^(.{23})/, "$1-");
  };

  if (apiKey.indexOf("-") !== -1) {
    return apiKey;
  }
  const hasPrefix = apiKey.indexOf(":") !== -1;
  if (hasPrefix) {
    const splitted = apiKey.split(":");
    const prefix = splitted[0];
    const restoredApiKey = prefix === "local" ? splitted[1] : restore(splitted[1]);
    return `${prefix}:${restoredApiKey}`;
  }

  return restore(apiKey);
};
