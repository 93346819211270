import { runTest as runTestCore } from "../sdk/run-test";
import uniq from "lodash/uniq";
import { format as formatDate } from "date-fns";
import queryString from "query-string";
import { GetConnectionInfoForGenesysLogin } from "twillio-tests/tests";
import { GenesysReqAccessToken } from "twillio-tests/core/testConfiguration";

export interface TestRunState {
  initialized: boolean;
  config: any;

  lastRunRegular: number;
  lastRunThorough: number;
  lastConfigRead: number;

  nextEvents: number[];
  nextEventTime: number;
  reload: boolean;
}

export const initialTestRunState: TestRunState = {
  initialized: false,
  config: null,
  lastRunRegular: 0,
  lastRunThorough: 0,
  lastConfigRead: 0,
  nextEvents: [],
  nextEventTime: 0,
  reload: false,
};

const frequencyVariants: any = {
  "5min": 5,
  "15min": 15,
  "1hour": 60,
  "4hour": 60 * 4,
  "6hour": 60 * 6,
  "8hour": 60 * 8,
  "12hour": 60 * 12,
  daily: 60 * 24,
  never: 60 * 25,
};

export const runPeriodicTest = async (
  testRunState: TestRunState,
  _config: any,
  addLogLine: Function
): Promise<TestRunState> => {
  const newTestRunState = { ...testRunState };
  newTestRunState.nextEvents = [];
  newTestRunState.initialized = true;

  let isActive;
  try {
    newTestRunState.config = _config;
    isActive = newTestRunState.config.status;
  } catch (err) {
    console.log(err);
    isActive = false;
  }
  const params = queryString.parse(window.location.search);

  addLogLine(`Test is ${isActive ? "active" : "inactive"}`);

  const config = newTestRunState.config;
  const now = Date.now();

  let regularInterval = frequencyVariants[config.run_frequency] * 60000;
  const thoroughInterval = frequencyVariants[config.thorough_run_frequency] * 60000;
  if (params.debug === "continuous") {
    addLogLine("Debug is continuous");
    regularInterval = 10000;
  }
  if (typeof params.run === "string") {
    const testListParams = params.run.split(",");
    config.test_list = testListParams;
    config.testsList = testListParams;
    config.thorough_test_list = testListParams;
  }
  addLogLine("Test interval: " + config.run_frequency);
  addLogLine("Thorough interval: " + config.thorough_run_frequency);

  const restartClicked = localStorage.getItem("restartButton") == "true";
  const needToRunRegular = restartClicked || now > testRunState.lastRunRegular + regularInterval;
  localStorage.setItem("restartButton", "false");
  const needToRunThorough =
    now > testRunState.lastRunThorough + thoroughInterval &&
    config.thorough_run_frequency !== "never" &&
    config?.thorough_test_list?.length > 0;
  const oneHourInterval = 60 * 60000;

  if (isActive) {
    config.testsList = [];
    if (needToRunRegular || needToRunThorough) {
      newTestRunState.lastRunRegular = now;
      config.testsList = uniq((config.testsList || []).concat(config.test_list));
    }

    if (needToRunThorough) {
      newTestRunState.lastRunThorough = now;
      config.testsList = uniq((config.testsList || []).concat(config.thorough_test_list));
    }

    newTestRunState.nextEvents.push(newTestRunState.lastRunRegular + regularInterval);
    if (config.thorough_run_frequency !== "never" && config?.thorough_test_list?.length > 0) {
      newTestRunState.nextEvents.push(newTestRunState.lastRunThorough + thoroughInterval);
    }
  }

  newTestRunState.nextEvents.push(now + oneHourInterval);

  newTestRunState.nextEventTime = !isActive
    ? now + oneHourInterval
    : newTestRunState.nextEvents.reduce(
        (minimum: number, time: number) => Math.min(time, minimum),
        Number.MAX_SAFE_INTEGER
      );

  try {
    console.log("SECOND", { isActive, testsList: config.testsList });
    console.log();
    if (isActive && config.testsList.length > 0) {
      const runType = needToRunThorough ? "thorough" : "regular";
      config.runType = runType;
      config.datacenterRegions = config.datacenterRegions || [];

      if (runType === "thorough" && localStorage.getItem("page-restart") !== "true") {
        console.log("Case 2");

        localStorage.setItem("page-restart", "true");

        initialTestRunState.reload = true;
        return initialTestRunState;
      }
      if (config.runType === "thorough" && localStorage.getItem("page-restart") === "true") {
        console.log("Case 3");

        addLogLine("Probe restarted");
        localStorage.setItem("page-restart", "false");
      }

      addLogLine("Test started");

      runTestCore(config)
        .then((res: any) => {
          addLogLine("Test finished");
          res.logs.forEach((log: any) => {
            addLogLine(log);
          });
          addLogLine(
            "Next event is scheduled for: " +
              formatDate(new Date(newTestRunState.nextEventTime), "MMM d HH:mm")
          );
        })
        .catch((err: any) => {
          console.log(err);
          addLogLine("Test failed", err.message);
        });
    }
  } catch (err) {
    addLogLine(err.message);
    console.log(err);
  }

  return newTestRunState;
};

const generateTokenForGenesys = (config: any, environment: string, clientId: string) => {
  const queryStringData = {
    //response_type: "token", // this is for implicit grant
    response_type: "code", // this is for code authorization method
    client_id: clientId,
    redirect_uri: config.CallQuality?.additionalConfig?.codeAuth?.authRedirectURIs[0],
    prompt: "login",
    state: config.API_KEY,
  };

  const params = queryString.stringify(queryStringData);
  window.location.replace(`https://login.${environment}/oauth/authorize?${params}`);
};

export const doProcessForGenesys = async (config: any) => {
  if (config.CallQuality?.ConnectionInfoName === "genesys") {
    // need to generate access token
    // check in local storage and check expiration time
    // if access token not available then generate
    // if expiry time up then generate
    const homeRegion = config.options.region;
    const environment = config.options.environment;
    const clientId = config.options.clientId;
    const clientSecret = config.options.clientSecret;
    const dialUpNum = config.options.dialUpNum;
    const mediaRegion = config.options.mediaRegion;

    const authorizationCode = localStorage.getItem("genesys-authorization-code");
    if (authorizationCode) {
      localStorage.removeItem("genesys-authorization-code");

      const options: GenesysReqAccessToken = {
        redirectUri: config.CallQuality?.additionalConfig?.codeAuth?.authRedirectURIs[0],
        environment,
        clientId,
        clientSecret,
        authorizationCode,
      };

      try {
        const { data, status } = await GetConnectionInfoForGenesysLogin(options);

        if (status !== 200 || (data && data.error)) {
          // error
          // initiate full auth
          console.log("subham:Genesys:log: getGenesysAccessToken error", data?.error);
          initiateGenesysFullAuthSequence(config, environment, clientId);
        } else {
          localStorage.setItem("genesys-access-token", data.access_token);
          localStorage.setItem("genesys-access-token-expiry-duration", data.expires_in);
          localStorage.setItem("genesys-refresh-token", data.refresh_token);
          const tokenFetchTime = Date.now();
          localStorage.setItem("genesys-last-token-fetch-time", tokenFetchTime.toString());

          const accessToken = data.access_token;
          config.CallQuality.genesysConfig = {
            accessToken,
            environment,
            dialUpNum,
            mediaRegion,
            homeRegion,
            clientId,
            clientSecret,
          };
        }
      } catch (error) {
        console.log("subham:Genesys:log: getGenesysAccessToken error");
        initiateGenesysFullAuthSequence(config, environment, clientId);
      }
    } else {
      const accessToken = localStorage.getItem("genesys-access-token");
      if (accessToken) {
        // check for expiration time
        const genesysExpirationTime = Number(
          localStorage.getItem("genesys-access-token-expiry-duration")
        );
        const genesysLastTokenFetchTime = Number(
          localStorage.getItem("genesys-last-token-fetch-time")
        );
        let isTokenExpire: boolean = false;
        const timeElapsed = (Date.now() - genesysLastTokenFetchTime) / 1000;
        // wait till last 2 mintues of expiration and declare token expiration
        if (timeElapsed >= genesysExpirationTime - 120) {
          isTokenExpire = true;
        }
        if (isTokenExpire) {
          // procedure to get token via refresh token
          // if fails then start full auth sequence again
          const refreshToken = localStorage.getItem("genesys-refresh-token");
          if (refreshToken) {
            const options: GenesysReqAccessToken = {
              environment,
              clientId,
              clientSecret,
              refreshToken,
            };

            try {
              const { data, status } = await GetConnectionInfoForGenesysLogin(options);
              if (status !== 200 || (data && data.error)) {
                // error
                // initiate full auth
                console.log("subham:Genesys:log: getGenesysAccessToken error", data?.error);
                initiateGenesysFullAuthSequence(config, environment, clientId);
              } else {
                localStorage.setItem("genesys-access-token", data.access_token);
                localStorage.setItem("genesys-access-token-expiry-duration", data.expires_in);
                localStorage.setItem("genesys-refresh-token", data.refresh_token);
                const tokenFetchTime = Date.now();
                localStorage.setItem("genesys-last-token-fetch-time", tokenFetchTime.toString());
                const accessToken = data.access_token;
                config.CallQuality.genesysConfig = {
                  accessToken,
                  environment,
                  dialUpNum,
                  mediaRegion,
                  homeRegion,
                  clientId,
                  clientSecret,
                };
              }
            } catch (error) {
              console.log("subham:Genesys:log: getGenesysAccessToken refresh error");
              initiateGenesysFullAuthSequence(config, environment, clientId);
            }
          } else {
            // no refresh token
            // do full auth sequence
            console.log("subham:Genesys:log: no refresh token available");
            initiateGenesysFullAuthSequence(config, environment, clientId);
          }
        } else {
          config.CallQuality.genesysConfig = {
            accessToken,
            environment,
            dialUpNum,
            mediaRegion,
            homeRegion,
            clientId,
            clientSecret,
          };
        }
      } else {
        // procedure to get token
        console.log("subham:Genesys:log: no access token available");
        initiateGenesysFullAuthSequence(config, environment, clientId);
      }
    }

    // const accessToken = localStorage.getItem("genesys-access-token");
    // if (accessToken) {
    //   // check for expiration time
    //   const genesysExpirationTime = Number(
    //     localStorage.getItem("genesys-access-token-expiry-duration")
    //   );
    //   const genesysLastTokenFetchTime = Number(
    //     localStorage.getItem("genesys-last-token-fetch-time")
    //   );
    //   let isTokenExpire: boolean = false;
    //   const timeElapsed = (Date.now() - genesysLastTokenFetchTime) / 1000;
    //   if (timeElapsed >= (genesysExpirationTime - 600)) {
    //     isTokenExpire = true;
    //   }
    //   if (isTokenExpire) {
    //     // procedure to get token
    //     generateTokenForGenesys(config, environment, clientId);
    //   } else {
    //     config.CallQuality.genesysConfig = {
    //       accessToken,
    //       environment,
    //       dialUpNum,
    //       mediaRegion,
    //       homeRegion,
    //       clientId,
    //       clientSecret,
    //     };
    //   }
    // } else {
    //   // procedure to get token
    //   generateTokenForGenesys(config, environment, clientId);
    // }
  }
};

// const getParameterByName = (name: any) => {
//   name = name.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
//   var regex = new RegExp("[\\#&]" + name + "=([^&#]*)"),
//     results = regex.exec(window.location.hash);
//   return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
// };

export const setTokenForGenesys = () => {
  // this is for implicit grant
  // ....
  // https://login.mypurecloud.com/oauth/authorize
  // ?client_id=<my-client-id>
  // &response_type=code
  // &redirect_uri=<http://example.com/oauth/callback>
  // &target=<orgid>
  // &state=<uri%20encoded%20value>

  // in such a case read state and redirect again
  // const token = getParameterByName("access_token");
  // const stateParam = getParameterByName("state");
  // const expiry = getParameterByName("expires_in");
  // if (stateParam && token && expiry) {
  //   localStorage.setItem("genesys-access-token", token);
  //   localStorage.setItem("genesys-access-token-expiry-duration", expiry);
  //   const tokenFetchTime = Date.now();
  //   localStorage.setItem("genesys-last-token-fetch-time", tokenFetchTime.toString());
  //   const generateRedirect = `${window.location.origin}/?apiKey=${stateParam}`;
  //   window.location.replace(generateRedirect);
  // }

  // this is for authorization code
  // ....
  // http://example.com/oauth/callback?code=my-authorization-code

  const queryParams = queryString.parse(window.location?.search);

  const code = queryParams?.code as string;
  const stateParam = queryParams?.state as string;
  if (stateParam && code) {
    localStorage.setItem("genesys-authorization-code", code);
    const generateRedirect = `${window.location.origin}/?apiKey=${stateParam}`;
    window.location.replace(generateRedirect);
  }
};

const initiateGenesysFullAuthSequence = (config: any, environment: string, clientId: string) => {
  localStorage.removeItem("genesys-access-token");
  localStorage.removeItem("genesys-access-token-expiry-duration");
  localStorage.removeItem("genesys-refresh-token");
  localStorage.removeItem("genesys-last-token-fetch-time");
  generateTokenForGenesys(config, environment, clientId);
};
