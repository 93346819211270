import green from "@material-ui/core/colors/green";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { Theme } from "@material-ui/core";

const defaultTheme = {
  palette: {
    primary: {
      light: "#6598d1",
      main: "#4682C3",
      dark: "#3e76b2",
      contrastText: "#ffffff",
    },
    secondary: {
      light: green[500],
      main: "#559542",
      dark: green[800],
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: ".8125rem",
      },
    },
    MuiTableSortLabel: {
      icon: {
        height: 15,
        width: 15,
        marginLeft: 2,
        marginTop: 1,
      },
    },
    MuiTypography: {},
    MuiButton: {
      containedPrimary: {},
    },
  },
} as Theme;

export default {
  defaultTheme,
  freshworks: merge(cloneDeep(defaultTheme), {
    palette: {
      primary: {
        main: "#bd5081",
        dark: "#2D1032",
        light: "#f5f5f5",
      },
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#9a285b",
          },
        },
      },
      MuiTypography: {
        h1: {
          color: "#2D1032",
        },
        h2: {
          color: "#2D1032",
        },
        h3: {
          color: "#2D1032",
        },
        h4: {
          color: "#bd5081",
        },
        h5: {
          color: "#2D1032",
        },
        h6: {
          color: "#2D1032",
        },
        body1: {
          color: "#2D1032",
        },
        body2: {
          color: "#2D1032",
        },
      },
    },
  }),
} as any;
