import * as React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Probe from "./containers/Probe";
import NotFoundPage from "./containers/NotFoundPage";
import { NotFound } from "./constants/RoutesNames";

const history = createBrowserHistory();

class RouterContainer extends React.Component<{}> {
  public render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path={NotFound} component={NotFoundPage} />

          <Route path="app/:apiKey?" component={Probe} />
          <Route path="/" component={Probe} />

          <Redirect exact={true} from="/" to={"/app"} />
          <Redirect from="*" to={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default RouterContainer;
