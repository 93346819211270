// tslint:disable: no-any
import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import isArray from "lodash/isArray";
import startCase from "lodash/startCase";
import isObject from "lodash/isObject";

export type LogColors = "red" | "green" | "black" | "orange";
export interface ILog {
  message: string;
  color: LogColors;
  time?: any;
}

interface ILogViewProps {
  classes: any;
  logs: ILog[];
}

const styles = (theme: any) => ({
  message: {
    fontSize: 14,
    fontWeight: 400,
    paddingBottom: 5,
    paddingRight: 17,
    "&.red": {
      color: "red",
    },
    "&.green": {
      color: "green",
    },
  },
  content: {
    overflow: "auto",
    padding: "5px 70px 5px 5px",
    "& > div:last-child": {
      background: "#c9cbd1!important",
      width: "5px!important",
      "& div": {
        background: `${theme.palette.primary.main}!important`,
        width: "3px!important",
        left: 1,
        top: 1,
      },
    },
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
  table: {
    width: "100%",
    "& tr": {
      "& td": {
        "& p": {
          margin: 0,
          fontSize: 14,
          fontWeight: 400,
          paddingBottom: 5,
          paddingRight: 5,
          "&.red": {
            color: "red",
          },
          "&.green": {
            color: "green",
          },
        },
        "&:first-child": {
          width: 1,
        },
        "&:nth-child(2)": {
          width: 1,
          whiteSpace: "nowrap",
        },
        "&:last-child": {
          "& p": {
            fontWeight: 600,
          },
        },
      },
    },
  },
});

const LogView = (props: ILogViewProps) => {
  const { classes } = props;

  const plainMessage = (idx: number, message: string, _time: any, color: LogColors) => {
    if (!message) {
      return null;
    }
    const messageText = typeof message === "string" ? message : (message as Error).message;
    // e.g. === Location ===
    const isTestHeader = messageText.includes("===");
    const isTestFooter = messageText.includes("End ===");
    return (
      <Typography
        key={`${idx}_${Date.now()}`}
        // variant="p"
        className={`${classes.message} ${isTestFooter ? "black" : isTestHeader ? "green" : color}`}
      >
        {`${isTestHeader ? " " : "  -  "}${messageText}`}
      </Typography>
    );
  };

  const complexMessage = (idx: number, message: any, time: any, color: LogColors) => {
    const rows = getRows(message, time, color);
    return (
      <table key={`${idx}_${Date.now()}`} className={classes.table}>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const getRows = (object: any, time: any, color: LogColors, inner?: boolean): JSX.Element[] => {
    let result: any[] = [];
    Object.keys(object).forEach((key: string, index: number) => {
      const value = object[key];

      if (isArray(value)) {
        // if key is number from 0 to 100
        // it is jagged and we don't display number keys
        if (/^[1-9][0-9]?$|^100$/.test(key)) {
          const innerObjectHeaderRow = makeRow(`${startCase(key)}:`, "");
          result.push(innerObjectHeaderRow);
        }
        const innerRows = getRows({ ...value }, time, color);
        result = result.concat(innerRows);
        return;
      }

      if (isObject(value)) {
        const innerObjectHeaderRow = makeRow(`${startCase(key)}:`, "");
        result.push(innerObjectHeaderRow);
        const innerRows = getRows(value, time, color, true);
        result = result.concat(innerRows);
        return;
      }

      if (value !== undefined && value !== null) {
        const keyText = !inner ? `${startCase(key)}:` : `[${index + 1}] ${startCase(key)}:`;
        const row = makeRow(keyText, value);
        result.push(row);
      }
    });
    return result;
  };

  const makeRow = (keyText: string, value: any) => {
    return (
      <tr key={`${keyText}_${Math.random()}`}>
        <td>
          <p>-</p>
        </td>
        <td>
          <p>{keyText}</p>
        </td>
        <td>
          <p>{value.toString()}</p>
        </td>
      </tr>
    );
  };

  return (
    <div className={classes.content}>
      {props.logs.map((log: ILog, i: number) => {
        if (isObject(log.message)) {
          return complexMessage(i, log.message, log.time, log.color);
        }
        return plainMessage(i, log.message, log.time, log.color);
      })}
    </div>
  );
};

export default withStyles(styles)(LogView);
