import axios from "axios";

import { GetUserMediaTestType, ProbeTestConfiguration, TestConfiguration } from "twillio-tests/core/testConfiguration";
// import { CustomerConfiguration } from "twillio-tests/core/types/test-configuration";
import { TestNamesString } from "twillio-tests/config";

import { TestRTCSDKOptions } from "./types";
import { decomposeApiKey } from "../utils/apikey";

export async function getConfig(options: TestRTCSDKOptions): Promise<TestConfiguration | undefined> {
  let { apiKey, url, environment } = decomposeApiKey( options.apiKey, {
    local: `http://localhost:9101/v1`,
    staging: `https://api-staging.testrtc.com/v1st`,
    production: `https://api.testrtc.com/v1`,
  });

  if ("production" === options.env) {
    url = "https://api.testrtc.com/v1";
  } else if ("staging" === options.env) {
    url = "https://api-staging.testrtc.com/v1st";
  }

  // for local setup we are using PR:{probeId} key
  // because of kong authentication api key schema
  // so for local use need to pass local:{probeId} and not generated apiKey
  apiKey = environment === "local" ? `PR:${apiKey}` : apiKey;

  const requestUrl = `${url}/networktest-config?instanceId=${options.instanceId || ""}`;
  const headers = {
    apikey: apiKey,
  };

  const configData: ProbeTestConfiguration = await axios.get(requestUrl, { headers })
    .then((res) => res.data);

  if (!configData) {
    return undefined;
  }

  const currentDate = new Date();
  const ProbeDate = new Date(configData.end_date);

  if (configData.end_date && ProbeDate.getTime() < currentDate.getTime()) {
    throw Error("Probe date have been Expired");
  }

  // if connection is of Genesys, do not print getConfig they have sensitive info in options field.
  if (configData.ConnectionInfoName === "genesys") {
    console.debug("Config", { url, requestUrl });
  } else {
    console.debug("Config", { url, requestUrl, config: configData });
  }

  let getUserMedia = {
    checkAudio: false,
    checkVideo: false,
    detailedTests: false,
    enumerateDevices: false,
  };

  const ignoreThoroughTests = configData.thorough_run_frequency === "never";
  const ignoreNormalTests = configData.run_frequency === "never";

  const checkAudio = {
    normal: configData.test_list.includes("CallQuality"),
    thorough: configData.thorough_test_list.includes("CallQuality")
  };
  const checkVideo = {
    normal: false,
    thorough: false
  };

  if (ignoreNormalTests) {
    getUserMedia["checkAudio"] = checkAudio.thorough;
  } else if (ignoreThoroughTests) {
    getUserMedia["checkAudio"] = checkAudio.normal;
  } else {
    getUserMedia["checkAudio"] = checkAudio.normal || checkAudio.thorough;
  }

  const videoTestNames: TestNamesString[] = ["VideoQuality", "VideoPlayback", "VideoP2P", "VideoBandwidth"];

  videoTestNames.forEach(testName => {
    if (configData.test_list.includes(testName) && !ignoreNormalTests) {
      checkVideo.normal = true;
      getUserMedia.checkVideo = true;
    }

    if (configData.thorough_test_list.includes(testName) && !ignoreThoroughTests) {
      checkVideo.thorough = true;
      getUserMedia.checkVideo = true;
    }
  });

  getUserMedia = {
    ...getUserMedia,
    checkAudio: getUserMedia.checkVideo || getUserMedia.checkAudio,
    detailedTests: configData.getUserMedia?.type ? configData.getUserMedia.type === GetUserMediaTestType.detailed : true,
    enumerateDevices: configData.getUserMedia?.type ? configData.getUserMedia.type !== GetUserMediaTestType.simple : true,
  };

  if (configData.test_list.length && !configData.test_list.includes("GetUserMedia") && (checkAudio.normal || checkVideo.normal)) {
    configData.test_list?.push("GetUserMedia");
  }

  if (configData.thorough_test_list.length && !configData.thorough_test_list.includes("GetUserMedia") && (checkAudio.thorough || checkVideo.thorough)) {
    configData.thorough_test_list?.push("GetUserMedia");
  }

  if (!getUserMedia.checkVideo && !getUserMedia.checkAudio) {
    configData.test_list = configData.test_list.filter(item => item !== "GetUserMedia");
    configData.thorough_test_list = configData.thorough_test_list.filter(item => item !== "GetUserMedia");
  }

  const config = configData as any as TestConfiguration;
  const configOptions: any = {};
  configOptions["region"] = config.region?.toLowerCase();

  // for genesys https://redmine.testrtc.com/issues/8086 point 3
  // adding clientid, clientsecret mediaregion environment dialupnum
  configOptions["clientId"] = config.clientid;
  configOptions["clientSecret"] = config.clientsecret;
  configOptions["mediaRegion"] = config.mediaregion?.toLowerCase();
  configOptions["environment"] = config.environment?.toLowerCase();
  configOptions["dialUpNum"] = config.dialupnum;
  configOptions["wakeLock"] = !!config.wakeLock;

  config.getUserMedia = getUserMedia;
  config.options = configOptions;
  config.API_KEY = apiKey;
  config.isProbe = true;
  config.API_URL = `${url}/networktest`;
  config.CredentialsApiUrl =
    environment === "local" ? "http://localhost:80/access" : config.CredentialsApiUrl;
  return config;
}
